import React from 'react';
import { CustomText } from '@components/ui';
import { NormalCheckbox } from '@components/ui/Checkbox';

type Props = {
  count: number;
  name: string;
  isActive: boolean;
  onClick: () => void;
};

export default function ExtensionCheckbox({ count, name, isActive, onClick }: Props) {
  const isCountZero = count === 0;

  return (
    <div className={`gap-2 flex items-center h-5 ${isCountZero && 'opacity-50'}`}>
      <NormalCheckbox value={!isCountZero && isActive ? 'y' : 'n'} htmlFor={name} onClick={onClick} />
      <div onClick={onClick} className={'cursor-pointer'}>
        <CustomText size={12} weight={500}>
          {name} ({count})
        </CustomText>
      </div>
    </div>
  );
}
