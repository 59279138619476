import { EXTENSION_CODE } from 'src/constants';

// 확장자 코드 리스트
export const EXTENSIONS = [[EXTENSION_CODE.SKP, EXTENSION_CODE.CS3O], [EXTENSION_CODE.BLEND], [EXTENSION_CODE.OBJ, EXTENSION_CODE.FBX], [EXTENSION_CODE.ETC]];

export const SORT_CODE = {
  NEWEST: 'NEWEST',
  POPULARITY: 'POPULARITY',
  RECOMMENDED: 'RECOMMENDED',
  ACCURACY: 'ACCURACY',
  LOWER_PRICE: 'LOWER_PRICE',
  HIGHER_PRICE: 'HIGHER_PRICE',
};

export const SORTS = [
  {
    key: SORT_CODE.POPULARITY,
    value: 'event.order.popularity',
  },
  {
    key: SORT_CODE.NEWEST,
    value: 'event.order.newest',
  },
  {
    key: SORT_CODE.LOWER_PRICE,
    value: 'event.order.lowerPrice',
  },
  {
    key: SORT_CODE.HIGHER_PRICE,
    value: 'event.order.higherPrice',
  },
  {
    key: SORT_CODE.RECOMMENDED,
    value: 'event.order.featured',
  },
];
