import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CustomText } from '@components/ui';
import { CustomIcon } from '@components/ui/Icon';
import { KeyValue } from '@components/ui/SelectBox';
import { EXTENSION_CODE } from 'src/constants';
import { ExtensionCount } from '@components/common/Products/type';
import ExtensionCheckbox from './ExtensionCheckbox';
import * as Styled from './styled';
import { EXTENSIONS, SORT_CODE } from './constants';

type Props = {
  sorts: KeyValue[];
  extensionCount: ExtensionCount;
  selectedExtensions: string[];
  selectedSort: string;
  totalCount: number;
  onSelectExtensions: (extension: string[]) => void;
  onSelectSort: (sort: string) => void;
  isShowFeatured?: boolean;
  isSort?: boolean;
};

export default function ProductFilter({
  sorts,
  extensionCount,
  selectedExtensions,
  selectedSort,
  totalCount,
  onSelectExtensions,
  onSelectSort,
  isShowFeatured = false,
  isSort = true,
}: Props) {
  const { t } = useTranslation('common');
  const dropMenuRef = useRef<HTMLDivElement>(null);
  const [isDisplayDropMenu, setIsDisplayDropMenu] = useState<boolean>(false);

  const handleExtensionCheckboxClick = (extensions: string[]) => {
    const isNotIncludeItems = selectedExtensions.some((name) => extensions.includes(name));
    if (isNotIncludeItems) {
      const filterItems = selectedExtensions.filter((name) => !extensions.includes(name));
      onSelectExtensions([...filterItems]);
      return;
    }
    onSelectExtensions([...selectedExtensions, ...extensions]);
  };

  const handleExtensionAllClick = () => {
    if (selectedExtensions.length > 0) onSelectExtensions([]);
  };

  const handleSortItemClick = (sort: typeof SORT_CODE[keyof typeof SORT_CODE]) => {
    onSelectSort(sort);
    setIsDisplayDropMenu(false);
  };

  // 마이페이지 메뉴 외에 다른 곳을 클릭한 경우 마이페이지 메뉴 닫음.
  const handleOutSideClick = (event) => {
    if (!dropMenuRef.current.contains(event.target)) setIsDisplayDropMenu(false);
  };

  // 바깥 영역을 클릭하면 드랍다운 메뉴 숨김
  useEffect(() => {
    if (isDisplayDropMenu) {
      // document 클릭 이벤트
      document.addEventListener('click', handleOutSideClick, true);
      return () => {
        document.removeEventListener('click', handleOutSideClick, true);
      };
    }
  });

  return (
    <Styled.ProductFilterWrapper>
      <div className={'gap-10 flex'}>
        <ExtensionCheckbox
          isActive={selectedExtensions.length === 0}
          count={totalCount}
          name={t('mypage.scrap.filter.selectAll')}
          onClick={handleExtensionAllClick}
        />

        {EXTENSIONS.map((name, i) => {
          const extensionCounts = [
            extensionCount.skp_cs3o,
            extensionCount.blend,
            extensionCount.obj_fbx,
            extensionCount.etc,
          ];
          const extensionName = name.join(' / ');
          const count = extensionCounts[i];
          const isActive = selectedExtensions.some((x) => name.includes(x as EXTENSION_CODE));

          return (
            <ExtensionCheckbox
              key={i}
              isActive={isActive}
              count={count}
              name={extensionName}
              onClick={() => {
                handleExtensionCheckboxClick(name);
              }}
            />
          );
        })}
      </div>

      {isSort && (
        <div className={'relative'}>
          <Styled.SortSelectBox
            onClick={() => {
              setIsDisplayDropMenu(!isDisplayDropMenu);
            }}
            ref={dropMenuRef}
          >
            <Styled.SortSelectBoxText>
              {(() => {
                const activeSortName = sorts.find((x) => x.key === selectedSort);
                return activeSortName ? t(activeSortName.value) : '';
              })()}
            </Styled.SortSelectBoxText>
            {isDisplayDropMenu ? (
              <CustomIcon
                src="/icons/goods/sort-dropdown-active-icon.svg"
                alt="dropdown"
                title="dropdown"
                width="20px"
                height="20px"
                priority={true}
              />
            ) : (
              <CustomIcon
                src="/icons/goods/sort-dropdown-icon.svg"
                alt="dropdown"
                title="dropdown"
                width="20px"
                height="20px"
                priority={true}
              />
            )}
          </Styled.SortSelectBox>

          <Styled.SortDropMenu isShow={isDisplayDropMenu}>
            {sorts.map((sort, idx) => {
              // 만약 추천 순이 아니면 무조건 표시하면서 해당 리스트가 수동 정렬이면 추천 순도 표시
              const isShow =
                sort.key !== SORT_CODE.RECOMMENDED || (sort.key === SORT_CODE.RECOMMENDED && isShowFeatured);

              if (isShow) {
                return (
                  <Styled.SortItem
                    key={idx}
                    onClick={() => {
                      handleSortItemClick(sort.key);
                    }}
                  >
                    <CustomText size={12}>{t(sort.value)}</CustomText>
                  </Styled.SortItem>
                );
              }
              return null;
            })}
          </Styled.SortDropMenu>
        </div>
      )}
    </Styled.ProductFilterWrapper>
  );
}
