import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Head } from '@components/layout';
import { LoadingScreen } from './common';

type Props = {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogTwitterTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogImageOriginal?: boolean;
  ogImageUrl?: string;
  ogTwitterCardSize?: 'summary' | 'summary_large_image' | 'app' | 'player';
  isMobileView?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (...args: any) => void;
  noUseRouteReady?: boolean;
};

const Page: React.FC<Props> = ({
  children,
  title = '',
  description = '',
  ogTitle = '',
  ogTwitterTitle = '',
  ogDescription = '',
  ogUrl = '',
  ogImageOriginal = false,
  ogImageUrl = '',
  ogTwitterCardSize = '',
  isMobileView = false,
  noUseRouteReady = false,
  ...other
}) => {
  const { isReady } = useRouter();

  return (
    <>
      <Head
        title={title}
        description={description}
        ogTitle={ogTitle}
        ogTwitterTitle={ogTwitterTitle}
        ogDescription={ogDescription}
        ogImageOriginal={ogImageOriginal}
        ogImageUrl={ogImageUrl}
        ogUrl={ogUrl}
        ogTwitterCardSize={ogTwitterCardSize}
        isMobileView={isMobileView}
      />
      <div {...other}>{children}</div>
      <LoadingScreen isDisplay={!isReady && !noUseRouteReady} />
    </>
  );
};

export default Page;
