import styled, { keyframes } from 'styled-components';
import { FlexCenterOneItem, FlexWrap } from '@components/wrapper';

export const skeletonAnimation = keyframes`
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
`;

export const ProductFilterGroupContainer = styled.div`
  margin-bottom: 100px;
`;

export const CosmicDealBannerInCard = styled(FlexCenterOneItem)<{ scrollBarWidth: number }>`
  height: 100px;
  background-color: #f8f8fb;
  width: 100vw;
  margin: 52px 0px 100px 0px;
  margin-left: calc(-50vw + 50% - ${(props) => (props.scrollBarWidth !== 0 ? props.scrollBarWidth / 2 : 0)}px);
`;

export const CardSkeletonGroup = styled(FlexWrap)`
  margin-top: 60px;
`;

export const CardSkeleton = styled.div`
  width: 276px;
  height: 328px;
  padding-bottom: 28px;
  background-image: linear-gradient(lightgray 172px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0),
    linear-gradient(lightgray 16px, transparent 0);
  background-repeat: repeat-y;
  background-size: 100% 328px, 180px 304px, 90px 304px, 32px 304px;
  background-position: 0px 0px, 0px 188px, 188px 214px, 0px 214px;
  animation: ${skeletonAnimation} 1.5s ease-in-out infinite;

  &:nth-last-of-type(-n + 3) {
    margin-bottom: 0px;
  }
`;

export const NoItemsContainer = styled.div`
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const NoItemsTextWrapper = styled.div`
  text-align: center;
`;
